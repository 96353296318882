import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { BsBuildingFillGear, BsCoin, BsPiggyBankFill } from 'react-icons/bs';
import { FaBars, FaHistory, FaPhone, FaStar } from 'react-icons/fa';
import { GiMiner } from 'react-icons/gi';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import Loader from './Loader';
import moment from 'moment';
import firebaseApp from '../Firebase/firebase';
import { MdOutlineBackup } from 'react-icons/md';
import { GrAnalytics } from 'react-icons/gr';

let alldata = []

export default function AdminSidebar() {
    const [collapsed, setCollapsed] = useState(isMobile ? true : false)
    const [toggled, setToggled] = useState(false)
    const location = useLocation()

    const [download, setDownload] = useState([])
    const [BackupModal, setBackupModal] = useState(false);
    const closeBackupModal = () => setBackupModal(false);
    const showBackupModal = () => setBackupModal(true);
    const [showLoader, setShowLoader] = useState(false)


    const handleCollapsedChange = () => {
        if (collapsed) {
            if (!isMobile) {
                document.getElementById('page-container').setAttribute('class', 'BigScreen')
            }
        } else {
            if (!isMobile) {
                document.getElementById('page-container').setAttribute('class', 'SmallScreen')
            }
        }
        setCollapsed(!collapsed);

    };

    const collectionNames = [
        "Cipher-Data",
        "Daily-Combos",
        "Package",
        "Miners",
        "Users",
        "transaction",
        "ytTask"
    ];

    const handleBackup = () => {
        setShowLoader(true)

        collectionNames.forEach(async (item) => {
            let data = []
            let obj = {}
            const db = firebaseApp.firestore();
            const querySnapshot = await db.collection(item).get();
            querySnapshot.forEach((doc) => {

                // item.push(doc.data())
                data.push(doc.data())


            });
            obj[item] = data
            alldata.push(obj)
            console.log(alldata)
            setDownload(alldata)
            if (collectionNames.length == alldata.length) {
                setShowLoader(false)
                showBackupModal()
            }
        })
    }

    const downloadData = () => {

        try {
            const jsonContent = JSON.stringify(download, null, 2);
            const blob = new Blob([jsonContent], { type: 'application/json' });

            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', moment(Date.now()).format('MMMM Do YYYY') + '.json');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                closeBackupModal()
            }
        } catch (error) {
            console.error('Error exporting download: ', error);
        }

    }

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <Sidebar
                backgroundColor="#fff"
                className={`app ${toggled ? "toggle" : ""}`}
                style={{ height: "100%", position: "fixed", zIndex: 51, boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.2)", color: "#000" }}
                collapsed={collapsed}
                onBackdropClick={() => setToggled(false)}
                toggled={toggled}
                breakPoint="lg"
            >
                <Menu iconshape="square">
                    <div>
                        {collapsed ?
                            <MenuItem
                                icon={<IoIosArrowDroprightCircle />}
                                onClick={handleCollapsedChange}
                                className='py-2'
                            ></MenuItem>
                            : <MenuItem
                                suffix={<IoIosArrowDropleftCircle />}
                                onClick={handleCollapsedChange}
                                className='py-2'
                            >
                                <div
                                    style={{
                                        padding: "7px",

                                    }}
                                >
                                    <h1>ADMIN</h1>
                                    {/* <img className='img-fluid mt-3' src={logo} alt='logo'></img> */}
                                </div>
                            </MenuItem>}
                    </div>

                    <hr className='m-0' />
                </Menu>
                <Menu>
                    {/* <MenuItem className={location.pathname == '/' ? "ActiveSidebar" : ""} component={<Link to={'/home'}></Link>} icon={<GiMiner style={{ fontSize: "20px" }} />}>Add Mining</MenuItem>
                    <MenuItem className={location.pathname == '/earningsection' ? "ActiveSidebar" : ""} component={<Link to={'/earningsection'}></Link>} icon={<BsCoin style={{ fontSize: "20px" }} />}>Earning Section</MenuItem>
                    <MenuItem className={location.pathname == '/dailycombo' ? "ActiveSidebar" : ""} component={<Link to={'/dailycombo'}></Link>} icon={<BsCoin style={{ fontSize: "20px" }} />}>Daily Combo</MenuItem>
                    <MenuItem className={location.pathname == '/dailycipher' ? "ActiveSidebar" : ""} component={<Link to={'/dailycipher'}></Link>} icon={<BsCoin style={{ fontSize: "20px" }} />}>Daily Cipher</MenuItem> */}
                    {/* <MenuItem className={location.pathname == '/special' ? "ActiveSidebar" : ""} component={<Link to={'/special'}></Link>} icon={<FaStar style={{ fontSize: "20px" }} />}>Add Special</MenuItem> */}
                    <MenuItem className={location.pathname == '/contact' ? "ActiveSidebar" : ""} component={<Link to={'/contact'}></Link>} icon={<FaPhone style={{ fontSize: "20px" }} />}>Contact</MenuItem>
                    <MenuItem className={location.pathname == '/admin-analytics' ? "ActiveSidebar" : ""} component={<Link to={'/admin-analytics'}></Link>} icon={<GrAnalytics style={{ fontSize: "20px" }} />}>Admin Analytics</MenuItem>
                    <MenuItem className={location.pathname == '/admin-analytics' ? "ActiveSidebar" : ""} component={<Link to={'/transactions'}></Link>} icon={<FaHistory style={{ fontSize: "20px" }} />}>Transactions</MenuItem>
                    {/*<MenuItem className={location.pathname == '/admin-transaction' ? "ActiveSidebar" : ""} component={<Link to={'/admin-transaction'}></Link>} icon={<FaHistory style={{ fontSize: 20 }} />}>Admin Transaction</MenuItem>
                    <MenuItem className={location.pathname == '/add-deposit' ? "ActiveSidebar" : ""} component={<Link to={'/add-deposit'}></Link>} icon={<BsPiggyBankFill style={{ fontSize: "20px" }} />}>Add Deposit</MenuItem>*/}
                    {/* <MenuItem className={location.pathname == '/special' ? "ActiveSidebar" : ""} component={<Link onClick={handleBackup}></Link>} icon={<MdOutlineBackup style={{ fontSize: "20px" }} />}>Backup</MenuItem> */}

                </Menu>
            </Sidebar>
            <div className="adminHeaderClass" style={{ position: 'fixed', padding: "15px" }}>
                <div className="w-100 d-flex justify-content-between">
                    <button className='btn p-0' onClick={() => setToggled(!toggled)}>
                        <FaBars style={{ color: '#000', fontSize: "20px" }} />
                    </button>

                </div>

            </div>
            <Modal show={BackupModal} onHide={closeBackupModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Backup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-center'> Do you want to download backup?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={downloadData}>
                        yes
                    </Button>
                    <Button variant="secondary" onClick={closeBackupModal}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
